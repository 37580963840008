
import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'

export default class MenuBarComponent extends Component {
  init () {
    this.buttonMenu = this.element.querySelector('.icon-button--menu')
    this.buttonMenu.addEventListener('click', () => fireCustomEvent('open-menu-overlay'))
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-bar').forEach(element => {
  element.instance = element.instance || new MenuBarComponent(element)
}))
