class FileFieldComponent {
  constructor (element) {
    this.element = element

    this.init()

    return this
  }

  init () {
    this.input = this.element.querySelector('.file-field__input')
    const output = this.element.querySelector('.file-field__filename')

    this.input.onchange = function () {
      output.textContent = this.files[0].name
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.file-field').forEach(element => {
  element.instance = element.instance || new FileFieldComponent(element)
}))
