import Component from '../../../assets/scripts/modules/component'

export default class CardList extends Component {
  init () {
    // Accordion
    this.button = this.element.querySelector('.card-list__accordion-button')
    this.region = this.element.querySelector('.card-list__accordion-wrapper')

    if (!this.button || !this.region) {
      return
    }

    this.button.addEventListener('click', () => this.toggleRegion())
  }

  toggleRegion () {
    if (this.button.getAttribute('aria-expanded') === 'true') {
      this.region.setAttribute('aria-hidden', true)
      this.button.setAttribute('aria-expanded', false)
      this.button.classList.remove('accordion--expanded')
    } else {
      this.region.setAttribute('aria-hidden', false)
      this.button.setAttribute('aria-expanded', true)
      this.button.classList.add('accordion--expanded')

      const rect = this.region.getBoundingClientRect()
      window.scroll({ top: (window.pageYOffset + rect.top) - (window.innerHeight / 3), left: 0, behavior: 'smooth' })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.card-list__inactive').forEach(element => {
  element.instance = element.instance || new CardList(element)
}))
