
// import konamiCode from './utilities/konami-code.js'

// Import in reverse order from stylesheets
require('../../components/structures/cookie-bar/cookie-bar.js')
require('../../components/structures/grid-overlay/grid-overlay.js')
require('../../components/structures/menu-bar/menu-bar.js')
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/organisms/article-cookies/article-cookies.js')
require('../../components/organisms/card-list/card-list.js')
require('../../components/organisms/image-header/image-header.js')
require('../../components/molecules/detail-image/detail-image.js')
require('../../components/molecules/form/form.js')
require('../../components/atoms/file-field/file-field.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/share-button/share-button.js')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 1000))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// This gives you vh-like values, but without scrollbars and addressbar and such
// This allows usage of document height / width in CSS
// NOTE: Only turn this on when needed - very heavy when resizing
// NOTE: Resize observer only from Safari 13.1 onwards, so back to window.onresize.
// new ResizeObserver(() => {
// ...
// }).observe(document.documentElement)
// const onResizeHandler = () => {
//  window.requestAnimationFrame(() => {
//    document.documentElement.style.setProperty('--100vw', `${document.documentElement.clientWidth}px`)
//    document.documentElement.style.setProperty('--100vh', `${document.documentElement.clientHeight}px`)
//    document.documentElement.style.setProperty('--100dw', `${document.documentElement.offsetWidth}px`)
//    document.documentElement.style.setProperty('--100dh', `${document.documentElement.offsetHeight}px`)
//  })
// }
// window.addEventListener('resize', () => onResizeHandler())
// onResizeHandler()

// loading="lazy" polyfill (~3kB) - for non-Chrome
if (!('loading' in HTMLImageElement.prototype)) {
  require('loading-attribute-polyfill')
}

// Import smooth scroll (~35kB) - for Safari and Edge
// if (!('scrollBehavior' in document.documentElement.style)) {
//  const smoothscroll = require('smoothscroll-polyfill')
//  smoothscroll.polyfill()
// }

if (window.navigator.userAgent.indexOf('Safari') !== -1) {
  // Web animations polyfill ~(50kB) - for Safari and Edge
  // About 50kb raw, so only use if you need it.
  // const x = import('web-animations-js')
}

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// Show debug styles on L hotkey and grid on G hotkey when document is focussed.
// if (WEBPACK_ENV.ENV === 'development') {
if (window.location.href.indexOf('fabriquehq') !== -1 || window.location.href.indexOf('127.0.0.1') !== -1) {
  document.addEventListener('keydown', event => {
    if (event.target !== document.body) {
      return
    }

    if (event.key === 'l') {
      document.documentElement.classList.toggle('debug-layout')
    }

    document.cookie = `debug-layout=${document.documentElement.classList.contains('debug-layout')}; path=/;`
  })

  document.documentElement.classList.toggle('debug-layout', document.cookie.indexOf('debug-layout=true') !== -1)
}
